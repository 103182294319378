.solution_page {
	background-color: var(--middle-grey-color);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
/*	justify-content: space-between;*/
	overflow: hidden;
}

.solution_page .v2_footer_section {
	margin-top: auto;
}

.solution_nav_section {
	background-color: var(--middle-grey-color);
	width: 83vw;
	min-height: 50px;
	padding: 20px 8.5vw;
/*	border-bottom: 1px solid var(--white-color);*/
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-bottom: 3vw;
}

.solution_nav_section .nav_section_holder{
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	gap: 3vw;
	overflow: auto;
	border: 1px solid var(--main-color);
	padding: 0 10px;

	-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */

  	mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 1),
    hsl(0 0% 0% / 1),
    hsl(0 0% 0% / 1),
    hsl(0 0% 0% / 0)
  );
}

.solution_nav_section .nav_section_holder::-webkit-scrollbar {
  display: none;
}

.solution_nav_section .nav_btn_title {
	height: var(--nav-font);
	font-size: var(--nav-font);
	line-height: var(--nav-font);
	color: var(--dark-color);
	background-color: var(--main-color);
	border: 1px solid var(--main-color);
	text-align: center;
	white-space: nowrap;
	padding: 1vw 9vw 1vw 10px;
}

.solution_nav_section .nav_btn {
	height: var(--nav-font);
	font-size: var(--nav-font);
	color: var(--white-color);
	cursor: pointer;
	transition: transform .2s;
	padding: 1vw 0;
}

.solution_nav_section .nav_btn:hover {
/*	transform: scale(1.5);*/
	color: var(--main-color);
}

.solution_nav_section .nav_btn:last-child {
	padding-right: 15vw;
}

.solution_nav_section .nav_btn_selected {
	border-bottom: 5px solid var(--main-color);
}

.solution_title_section {
	background-color: var(--middle-grey-color);
	width: 83vw;
	padding: 1vw 8.5vw;
}

.solution_title_section h2 {
	color: var(--main-color);
	font-size: var(--h2-text);
	font-weight: 600;
}

.solution_section {
	background-color: var(--middle-grey-color);
	width: 83vw;
	padding: 0 8.5vw 5vw 8.5vw;

	display: flex;
	flex-direction: row;
	gap: 2vw;
	flex-wrap: wrap;
}

.solution_section .solution_card {
	width: 15vw;
	border-radius: 5px;
	overflow: hidden;
	cursor: pointer;

	display: flex;
	flex-direction: column;
}

.solution_section .solution_card:hover {
	opacity: 0.7;
}

.solution_section .solution_card .img_section {
	width: 100%;
	min-height: 8vh;
	position: relative;
	overflow: hidden;
	background-color: var(--main-color);
}

.solution_section .solution_card img {
	width: 100%;
	position: absolute;
	top: 0;
	opacity: 0.3;
	transition: transform .2s;
}

.solution_section .solution_card img:hover {
	transform: scale(1.5);
}

.solution_section .solution_card .solution_card_info {
	background-color: var(--white-color);
	width: calc(100% - 2vw);
/*	height: 4vw;*/
	min-height: calc(100% - 8vh - 2vw);
	padding: 1vw;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.solution_section .solution_card .solution_card_info h2{
	font-size: var(--nav-font);
	font-weight: 600;
	padding: 8px 0;
}

.solution_section .solution_card .solution_card_info span{
	max-width: 40%;
	font-size: var(--tiny-font);
	font-weight: 400;
	color: var(--middle-grey-color);
	border: 1px solid var(--middle-grey-color);
	border-radius: 1vw;
	padding: 2px 5px;
}




.v2_solution_pop_up_bg {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
}

.v2_solution_pop_up_card {
	width: 70vw;
	height: 40vw;
	background-color: var(--white-color);
	opacity: 1;
	border-radius: 10px;
	overflow: hidden;

	display: flex;
	justify-content: center;
	align-items: center;
}

.v2_solution_pop_up_card_img_section {
	width: 35vw;
	height: 40vw;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.v2_solution_pop_up_card_img_section img{
	height: 40vw;
}

.v2_solution_pop_up_card_info_title {
	position: absolute;
	max-width: 70%;
/*	height: 5vw;*/
	padding: 5px;
	left: 5vw;
	top: 5vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color);
}

.v2_solution_pop_up_card_info_title h1 {
	font-size: var(--h2-text);
	font-weight: 600;
}

.v2_solution_pop_up_card_info_section1 {
	width: 25vw;
/*	height: 25vw;*/
	position: absolute;
	left: 5vw;
	bottom: 2vw;
	background-color: rgba(0, 0, 0, 0.6);
}

.v2_pop_info_section1_list_holder {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.v2_pop_info_section1_list_holder h2{
	font-size: var(--h2-text);
	color: var(--white-color);
	padding: 1vw 1vw 0 1vw;
}

.v2_pop_info_section1_list_holder hr{
	width: 100%;
	border: 1px solid var(--white-color);
	padding: 0;
}

.v2_pop_info_section1_list {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 1vw;
	padding: 1vw;
}

.v2_pop_info_section1_list_item {
	width: calc(50% - 1vw);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 1vw;
}

.v2_cube {
	display: block;
	min-width: var(--content-text);
	max-width: var(--content-text);
	min-height: var(--content-text);
	max-height: var(--content-text);
	background-color: var(--main-color);
}

.v2_pop_info_section1_list_item p {
	font-size: var(--content-text);
	color: var(--white-color);
}

.v2_solution_pop_up_card_info_section2 {
	width: 35vw;
	height: 40vw;
/*	overflow: hidden;*/
	overflow-y: auto;
	position: relative;
}

.v2_solution_pop_up_card_main_info {
	width: 29vw;
	height: 30vw;
	overflow: auto;
	padding: 5vw 3vw;
}

.v2_solution_pop_up_card_main_info h3 {
	font-weight: 600;
	font-size: calc(1.2*var(--content-text));
	line-height: calc(2*var(--content-text));
}

.v2_solution_pop_up_card_main_info p {
	font-size: var(--content-text);
	line-height: calc(1.2*var(--content-text));
}

.v2_solution_pop_up_card_info_section2 hr {
	width: 29vw;
	border: 0.5px solid var(--black-color);
	padding: 0;
}

.v2_solution_pop_up_card_btn_section {
	width: 35vw;
	height: 10vw;
}

.v2_solution_pop_up_card_close {
	position: absolute;
	width: 15px;
	height: 15px;
	top: 1vw;
	right: 1vw;
	background-color: var(--middle-grey-color);
}

.v2_display_none {
	display: none;
}




@media screen and (max-width:780px) {
	.solution_title_section {
		padding: 15px 8.5vw;
	}

	.solution_section {
		gap: 4vw;
	}

	.solution_section .solution_card {
		width: 25vw;
	}











	.v2_solution_pop_up_card {
    width: 90vw;
    height: 90vh;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	}
	.v2_solution_pop_up_card_img_section {
	  height: 50%;
	  width: 100%;
	}
	.v2_solution_pop_up_card_img_section img{
		height: 100%;
	  width: 100%;
	}
	.v2_solution_pop_up_card_info_section2 {
		height: 50%;
	  width: 100%;
	}
	.v2_solution_pop_up_card_info_section1 {
		width: calc(100% - 10vw);
		height: auto;
		bottom: 10px;
	}
	.v2_solution_pop_up_card_main_info {
		width: 84vw;
		height: auto;
	}
	.v2_solution_pop_up_card_info_section2 hr {
		width: 85vw;
	}
	.v2_solution_pop_up_card_btn_section {
		width: 100%;
	}
	.v2_solution_pop_up_card_close {
		position: absolute;
		width: 15px;
		height: 15px;
		top: 1vw;
		right: 1vw;
		background-color: var(--middle-grey-color);
	}
}

@media screen and (max-width:530px) {
	.solution_section .solution_card {
		width: 83vw;
	}

	.solution_section .solution_card .solution_card_info span{
		max-width: 20%;
	}

	.solution_section .solution_card .solution_card_info {
		width: calc(100% - 30px);
		min-height: calc(100% - 8vh - 60px);;
		padding: 15px;
	}






	
}


















