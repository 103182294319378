.v2_contact_us_holder {
	width: 90vw;

	padding: 100px 5vw;

}

.v2_contact_us_title {
	font-size: var(--h1-text);
	line-height: calc(2*var(--h1-text));
	text-align: center;
	font-weight: 600;
}

.v2_contact_us_img_holder {
	width: 100%;
/*	height: 20vw;*/

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	background-color: var(--main-color);
}

.v2_contact_us_img_holder .v2_contact_us_img {
	width: 28vw;
	height: 18vw;
	padding: 1vw;

	font-size: var(--h2-text);
	line-height: calc(2*var(--content-text));
	font-weight: 800;
	text-align: right;

	color: var(--white-color);

	background-position: center;
	background-size: cover;
}

.dark {
	opacity: 0.5;
	transition: 0.3s;
}

.dark:hover {
	opacity: 1;
}

.v2_contact_us_img_holder  .v2_contact_us_info_block{
	width: 28vw;
	height: 18vw;
	padding: 1vw;
	background-color: var(--semi-grey-color);

	background-position: top;
	background-size: 190% 190%;
}

.location h3{
	font-size: var(--content-text);
	line-height: calc(2*var(--content-text));
	text-align: left;
	font-weight: 800;
}

.location h4{
	font-size: var(--content-text);
	line-height: calc(2*var(--content-text));
	text-align: left;
}

.email h3{
	font-size: var(--content-text);
	line-height: calc(2*var(--content-text));
	text-align: left;
	font-weight: 600;
}





@media screen and (max-width:780px) {
	.v2_contact_us_holder {
		padding: 50px 5vw;

	}
  	.v2_contact_us_img_holder .v2_contact_us_img {
		width: 40vw;
		height: 25vw;
		padding: 2.5vw;
	}

	.v2_contact_us_img_holder  .v2_contact_us_info_block{
		width: 40vw;
		height: 25vw;
		padding: 2.5vw;
	}
	.dark {
		display: none;
	}
  
}

@media screen and (max-width:530px) {
  
}

@media screen and (max-width:420px) {
	.v2_contact_us_holder {
		padding: 50px 5vw;

	}
  	.v2_contact_us_img_holder .v2_contact_us_img {
		width: 80vw;
		height: 50vw;
		padding: 5vw;
	}

	.v2_contact_us_img_holder  .v2_contact_us_info_block{
		width: 80vw;
		height: 50vw;
		padding: 5vw;
	}
	.dark {
		display: none;
	}
}
















