@charset "utf-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  outline: none;
  box-shadow: none;
  background: none;
  border: none;
}
button:focus {
  outline: none;
  box-shadow: none;
  background: none;
  border: none;
}
textarea:focus, input:focus{
    outline: none;
}

@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

:root {
  --light-color: #ffffff;
  --white-color: #ffffff;
  --dark-color: #101820;
  --black-color: #000000;

  --main-color: #F5F41A;
  --sub1-color: #fe2464;
  --sub2-color: #56b77e;
  --sub3-color: #bf8800;

  --light-grey-color: #f1f1f1;
  --semi-light-grey-color: #f1f1f1;
  --middle-grey-color: #a1a1a1;
  --semi-grey-color: #e1e1e1;
  
  --nav-font: 1.2vw;
  --tiny-font: 0.9vw;
  --large-num-text: 10vw;
  --h1-text: 3.1vw;
  --h2-text: 1.7vw;
  --slide-title: 4vw;
  --slide-font: 1.2vw;
  --content-text: 1.1vw;
  --tech-title-text: 2vw;
  --tech-sub-text: 1.2vw;
}

@font-face {
  font-family: 'Google Sans';
  src: url('fonts/GoogleSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('fonts/GoogleSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.en * {
  font-family: 'Google Sans', sans-serif;
}

.zh * {
  font-family: 'Noto Sans Japanese';
}

.ja * {
  font-family: 'Noto Sans Japanese';
}


@media screen and (max-width:780px) {
  :root {
    --nav-font: 2vw;
    --tiny-font: 2vw;
    --large-num-text: 18vw;
    --h1-text: 3.5vw;
    --h2-text: 2.5vw;
    --content-text: 2vw;
    --slide-title: 6vw;
    --slide-font: 2vw;
    --tech-title-text: 3.5vw;
    --tech-sub-text: 2vw;
  }
}

@media screen and (max-width:640px) {
  :root {
    --nav-font: 2.2vw;
    --tiny-font: 2.2vw;
    --large-num-text: 19vw;
    --h1-text: 4vw;
    --h2-text: 3vw;
    --content-text: 2.5vw;
    --slide-title: 8vw;
    --slide-font: 2.5vw;
    --tech-title-text: 3vw;
    --tech-sub-text: 2vw;
  }
}

@media screen and (max-width:530px) {
  :root {
    --nav-font: 2.8vw;
    --tiny-font: 2.8vw;
    --large-num-text: 17vw;
    --h1-text: 4.5vw;
    --h2-text: 3.5vw;
    --content-text: 3vw;
    --slide-title: 8vw;
    --slide-font: 3vw;
    --tech-title-text: 3vw;
    --tech-sub-text: 2.5vw;
  }
}

@media screen and (max-width:420px) {
  :root {
    --nav-font: 3.3vw;
    --tiny-font: 3.3vw;
    --large-num-text: 30vw;
    --h1-text: 5vw;
    --h2-text: 4vw;
    --content-text: 3.5vw;
    --slide-title: 12vw;
    --slide-font: 4vw;
    --tech-title-text: 4vw;
    --tech-sub-text: 3vw;
  }
}






