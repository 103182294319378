.tech_bannar {
	background-color: var(--main-color);
	width: 80vw;
/*	min-height: 150px;*/
	padding: 0 10vw;

	position: relative;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.tech_bannar img {
/*	height: 100%;*/
	height: 12.5vh;
	padding-right: 20px;
}

.tech_bannar h1 {
	max-width: 50vw;
	font-size: var(--tech-title-text);
	line-height: calc(var(--tech-title-text)*1.3);
	font-weight: 700;
	color: var(--v1-dark-color);
	text-align: right;
}

.tech_bannar p {
	font-size: var(--tech-sub-text);
	color: var(--v1-dark-color);
    line-height: calc(var(--tech-sub-text)*1.5);
    text-align: right;
/*    padding-bottom: 10px;*/
/*	max-width: 500px;*/
/*	font-weight: 700;*/
}




.tech_intro {
	width: 100%;
	padding: 10%;

/*	background-color: var(--light-grey-color);*/
	
	display: flex;
	flex-direction: row;
	
}

.tech_intro .tech_intro_img{
	width: 60%;
/*	height: calc(80vw * 0.6 * 9/ 16);*/
	overflow: hidden;
	background-position: center;
	background-size: cover;
}

.tech_intro .tech_intro_content{
	width: 40%;
	min-height: calc(80vw * 0.6 * 9/ 16);
	overflow: hidden;
	background-color: var(--v1-dark-color);

	display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 5%;
}

.tech_intro .tech_intro_content h3{
	font-size: var(--tech-sub-text);
	color: var(--white-color);
    line-height: calc(var(--tech-sub-text)*1.5);
    text-align: left;
}

.tech_intro .tech_intro_content img{
	height: 6vh;
	padding-bottom: 20px;
}











.tech_cus_and_ps {
	width: 100%;
	padding: 10%;

	background-color: var(--light-grey-color);

	display: flex;
	flex-direction: row;
}

.tech_cus_and_ps .tech_cus{
	width: 40%;
	display: flex;
    flex-flow: column;
    padding: 20px 5%;
}

.tech_cus_and_ps .tech_cus h3{
	font-weight: 600;
	font-size: var(--h2-text);
	color: var(--v1-dark-color);
    line-height: calc(var(--h2-text)*2);
}

.tech_cus_and_ps .tech_cus h4{
	font-size: var(--v1-small-text);
	color: var(--v1-dark-color);
    line-height: calc(var(--v1-small-text)*1.5);
    text-align: left;
}

.tech_cus_and_ps .tech_ps{
	width: 40%;
	display: flex;
    flex-flow: column;
    padding: 20px 5%;
}

.tech_cus_and_ps .tech_ps h3{
	font-weight: 600;
	font-size: var(--h2-text);
	color: var(--v1-dark-color);
    line-height: calc(var(--h2-text)*2);
}

.tech_cus_and_ps .tech_ps h4{
	font-size: var(--v1-small-text);
	color: var(--v1-dark-color);
    line-height: calc(var(--v1-small-text)*1.5);
    text-align: left;
}




.read-more-button {
	text-decoration: underline;
	color: var(--v1-main-color);
	cursor: pointer;
}













.VideoGallery {
	background-color: var(--semi-grey-color);
	width: 100vw;
	margin-top: 50px;
}

.VideoGallery iframe {
	width: 30vw;
	height: 15vw;
	padding: 20px calc(10vw / 6);
}



















@media screen and (max-width:780px) {
  
}

@media screen and (max-width:530px) {
  
}

@media screen and (max-width:420px) {
	.tech_intro {
		flex-direction: column;
		width: 100vw;
		height: auto;
		padding: 0;
	}

	.tech_intro .tech_intro_img{
		width: 100%;
		min-height: calc(100vh - 200px);
	}

	.tech_intro .tech_intro_content{
		width: 90%;
	}



	.tech_cus_and_ps {
		flex-direction: column;
		margin-top: 20px;
	}

	.tech_cus_and_ps .tech_cus{
		width: 90%;
	}

	.tech_cus_and_ps .tech_ps{
		width: 90%;
	}



	.VideoGallery {
		width: 100vw;
		margin-top: 0px;
	}

	.VideoGallery iframe {
		width: 90vw;
		height: 45vw;
		padding: 20px 5vw;
	}
	
}











