.v2_mainpage_section1 {
	background-color: var(--black-color);
	width: 100vw;
	height: 60vw;
	padding: 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.v2_mainpage_section1 .v2_img_section {
	width: 20vw;
	height: 60vw;
	overflow: hidden;

	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: var(--dark-color);

	transition: background-color .2s;
}

.v2_mainpage_section1 .v2_img_section:hover {
	background-color: var(--main-color);
}

.v2_mainpage_section1 .v2_img_section img{
	height: 100%;
	position: absolute;
	top: 0;
	opacity: 0.3;
	transition: transform .2s;
	background-color: var(--main-color);
}

.v2_mainpage_section1 .v2_img_section img:hover {
	transform: scale(1.5);
}

.v2_mainpage_section1 .v2_img_section h1{
	line-height: calc(1.2*var(--content-text));
	font-size: var(--content-text);
	font-weight: 600;
	padding: 1vw 0;
	color: var(--white-color);
}

.v2_mainpage_section1 .v2_explore_btn {
	height: 5vw;
	width: 5vw;
	z-index: 100;
	color: var(--white-color);
	font-size: var(--h2-text);
	font-weight: 600;
	border: 2px solid var(--white-color);
	border-radius: 5vw;
}

.v2_mainpage_section1 .v2_explore_btn:hover {
	transform: scale(1.1);
}

.v2_mainpage_section1 .v2_word_section {
	width: 65vw;
	height: 55vw;
	overflow: auto;
	padding: 0 5vw 0 10vw;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.v2_mainpage_section1 .v2_word_section h1 {
	line-height: calc(1.2*var(--h1-text));
	font-size: var(--h1-text);
	font-weight: 600;
	padding: 2vw 0;
	color: var(--main-color);
}

.v2_mainpage_section1 .v2_word_section h2 {
	line-height: calc(1.2*var(--h2-text));
	font-size: var(--h2-text);
	font-weight: 600;
	padding: 1vw 0;
	color: var(--white-color);
}

.v2_mainpage_section1 .v2_word_section p {
	line-height: calc(1.2*var(--content-text));
	font-size: var(--content-text);
	font-weight: 400;
	color: var(--white-color);
}

.v2_mainpage_section1 .v2_word_section hr {
	width: 2%;
	border: 0.2px solid var(--white-color);
	margin: 2vw 0 1vw;

}

.v2_mainpage_section1 .v2_section_num {
	line-height: var(--content-text) !important;
	padding-left: 5px;
	border-left: 2px solid var(--white-color);
	color: var(--white-color) !important;
}

























.v2_mainpage_section2 {
	background-color: var(--black-color);
	width: 80vw;
	height: 60vw;
	padding: 0 10vw;

	display: flex;
	justify-content: center;
	align-items: center;
}
.v2_mainpage_section2 .v2_img_section {
	width: 40vw;
	height: 40vw;
	overflow: hidden;

	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: var(--dark-color);

	transition: background-color .2s;
}

.v2_mainpage_section2 .v2_img_section img{
	height: 60vw;
	position: absolute;
	top: 0;
	opacity: 0.3;
	transition: transform .2s;
	background-color: var(--sub1-color);
}

.v2_mainpage_section2 .v2_img_section h1{
	line-height: calc(1.2*var(--content-text));
	font-size: var(--content-text);
	font-weight: 600;
	padding: 1vw 0;
	color: var(--white-color);
}

.v2_mainpage_section2 .v2_explore_btn_holder {
	width: 20vw;
	padding: 1vw 2vw;
	cursor: pointer;

	display: flex;
	flex-direction: row;
	gap: 1vw;
	justify-content: flex-start;
	align-items: center;
}

.v2_mainpage_section2 .v2_explore_btn_holder:hover{
	transform: scale(1.1) !important;
}

.v2_mainpage_section2 .v2_explore_btn {
	height: 3vw;
	width: 3vw;
	z-index: 100;
	color: var(--white-color);
	font-size: var(--content-text);
	font-weight: 600;
	border: 1px solid var(--white-color);
	border-radius: 5vw;
}

.v2_mainpage_section2 .v2_word_section {
	width: 40vw;
	height: 30vw;
	
	z-index: 100;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.v2_mainpage_section2 .v2_sub_word_section {
	width: 70vw;
	height: 30vw;
	margin-left: -30vw;
	padding: 1vw 3vw 2vw 3vw;
	background-color: var(--sub1-color);
}

.v2_mainpage_section2 .v2_word_section h1 {
	line-height: calc(1.2*var(--h1-text));
	font-size: var(--h1-text);
	font-weight: 600;
	padding: 2vw 0 2vw 2vw;
	color: var(--sub1-color);
}

.v2_mainpage_section2 .v2_word_section h2 {
	line-height: calc(1.2*var(--h2-text));
	font-size: var(--h2-text);
	font-weight: 600;
	padding: 1vw 0;
	color: var(--white-color);
}

.v2_mainpage_section2 .v2_word_section p {
	line-height: calc(1.2*var(--content-text));
	font-size: var(--content-text);
	font-weight: 400;
	color: var(--white-color);
}

.v2_mainpage_section2 .v2_word_section hr {
	width: 2%;
	border: 0.2px solid var(--white-color);
	margin: 2vw 0 1vw;

}

.v2_mainpage_section2 .v2_section_num {
	line-height: var(--content-text) !important;
	padding-left: 5px;
	margin: 0 0 0 2vw;
	border-left: 2px solid var(--white-color);
	color: var(--white-color) !important;
}




.v2_mainpage_section3 {
	background-color: var(--black-color);
	width: 100vw;
	height: 60vw;
	padding: 0;

	display: flex;
	justify-content: center;
	align-items: center;
}
.v2_mainpage_section3 .v2_number_section {
	width: 25vw;
	height: 60vw;
	padding: 0 0 0 3vw;
	overflow: hidden;

	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2vw;

	background-color: var(--sub2-color);

	color: var(--white-color);

	transition: background-color .2s;
}

.v2_mainpage_section3 .v2_number_section hr {
	border: 1px solid var(--white-color);
}

.v2_mainpage_section3 .v2_number_word_holder:nth-child(odd) {
	padding-left: 5vw;
}

.v2_mainpage_section3 .v2_number_word_holder:nth-child(even) {
	padding-right: 5vw;
}

.v2_mainpage_section3 .v2_number_title {
}

.v2_mainpage_section3 .v2_large_number {
	font-size: var(--large-num-text);
	text-align: center;
	font-weight: 600;
}

.v2_mainpage_section3 .v2_three_digit {
	font-size: calc(var(--large-num-text)*5/6);
}

.v2_mainpage_section3 .v2_five_digit {
	font-size: calc(var(--large-num-text)*2/4);
	font-weight: 900;
}

.v2_mainpage_section3 .v2_word_section {
	width: 65vw;
	height: 55vw;
	overflow: auto;
	padding: 0 5vw 0 2vw;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.v2_mainpage_section3 .v2_word_section h1 {
	line-height: calc(1.2*var(--h1-text));
	font-size: var(--h1-text);
	font-weight: 600;
	padding: 2vw 0;
	color: var(--sub2-color);
}

.v2_mainpage_section3 .v2_word_section h2 {
	line-height: calc(1.2*var(--h2-text));
	font-size: var(--h2-text);
	font-weight: 600;
	padding: 1vw 0;
	color: var(--white-color);
}

.v2_mainpage_section3 .v2_word_section p {
	line-height: calc(1.2*var(--content-text));
	font-size: var(--content-text);
	font-weight: 400;
	color: var(--white-color);
}

.v2_mainpage_section3 .v2_word_section hr {
	width: 2%;
	border: 0.2px solid var(--white-color);
	margin: 2vw 0 1vw;

}

.v2_mainpage_section3 .v2_section_num {
	line-height: var(--content-text) !important;
	padding-top: 0px;
	margin: 2vw 0 0 0;
	padding-left: 5px;
	border-left: 2px solid var(--white-color);
	color: var(--white-color) !important;
}














@media screen and (max-width:780px) {
	.v2_mainpage_section1 {
		height: 120vh;
		flex-direction: column;
	}
	.v2_mainpage_section2 {
		height: 75vh;
		padding: 15vh 10vw 5vh 10vw;
	}
	.v2_mainpage_section3 {
		height: auto;
	}
	.v2_mainpage_section1 .v2_img_section {
		width: 100vw;
		height: 20vh;
	}
	.v2_mainpage_section1 .v2_img_section img{
		width: 100%;
		top: 0;
	}
	.v2_mainpage_section1 .v2_word_section {
		width: 80vw;
		height: 90vh;
		overflow: auto;
		padding: 5vh 10vw;
	}







	.v2_mainpage_section2 .v2_sub_word_section {
		width: 70vw;
		height: 70vw;
		margin-left: -30vw;
		padding: 1vw 3vw 2vw 3vw;
	}
	.v2_mainpage_section2 .v2_img_section {
		display: none;
	}
	.v2_mainpage_section2 .v2_word_section {
		width: 80vw;
		height: 30vw;
		
		z-index: 100;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
	.v2_mainpage_section2 .v2_sub_word_section {
		width: 70vw;
		height: auto;
		margin-left: 0;
		padding: 5vw;
		background-color: var(--sub1-color);
	}
	.v2_mainpage_section2 .v2_explore_btn_holder {
		width: 50vw;
		padding: 5vw 0vw;
		gap: 3vw;
	}

	.v2_mainpage_section2 .v2_explore_btn {
		height: 8vw;
		width: 8vw;
		border-radius: 5vw;
	}











	.v2_mainpage_section3 {
		flex-flow: column;
		flex-direction: column-reverse;
		justify-content: flex-end;
	}
	.v2_mainpage_section3 .v2_number_section {
		width: 90vw;
		height: auto;
		padding: 10vw 5vw;
		flex-flow: row;
		flex-wrap: wrap;
		align-items: center;
	}
	.v2_mainpage_section3 .v2_number_word_holder:nth-child(odd) {
		padding: 0 30px;

	}
	.v2_mainpage_section3 .v2_number_word_holder:nth-child(even) {
		padding: 0 30px;
		padding-top: 50px;
	}
	.v2_mainpage_section3 .v2_word_section {
		width: 80vw;
		height: auto;
		padding: 10vw;
	}
}


@media screen and (max-width:420px) {
	.v2_mainpage_section1 {
		height: 105vh;
		flex-direction: column;
	}
	.v2_mainpage_section1 .v2_word_section {
		height: 75vh;
	}






	.v2_mainpage_section3 .v2_number_word_holder:nth-child(odd) {
		padding: 20px 10vw 20px 0;

	}
	.v2_mainpage_section3 .v2_number_word_holder:nth-child(even) {
		padding: 20px 0 20px 10vw;
	}
}



