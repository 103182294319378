

:root {
	--v1-light-color: #ffffff;
	--v1-white-color: #ffffff;
	--v1-dark-color: #101820;

	--v1-main-color: rgb(60, 120, 255);

	--v1-light-grey-color: #f1f1f1;
	--v1-semi-light-grey-color: #f1f1f1;
	--v1-semi-grey-color: #a1a1a1;
}


@media only screen and (min-width: 0px) {
	:root {
		--v1-nav_bar_h: 50px;
		--v1-win_width: 380px;
		--v1-product_row_num: 2;
		--v1-list_row_num: 1;
		--v1-intro_row_num: 2;
		--v1-news_row_num: 1;
		--v1-main_page_item: 1;
		--v1-footer_item_num: 1;
		--v1-icon_item_num: 1;
		--v1-partner_num: 1;

		--v1-very-large-text: calc(40px);
		--v1-large-text: calc(30px);
		--v1-semi-large-text: calc(26px);
		--v1-normal-text: calc(20px);
		--v1-mid-small-text: calc(18px);
		--v1-small-text: calc(15px);
		--v1-semi-small-text: calc(11px);
	}

	.nav_btn_holder{
		width: 100%;
		padding: 0;
		flex-flow: column;
	}

	.nav_btn_holder .nav_btn_list{
		max-width: 100%;
	}

	.nav_btn_list h4{
		width: calc(100% - var(--v1-win_width)*1/10);
		padding: calc(var(--v1-win_width)*1/20);
		border-bottom: 1px solid var(--v1-white-color) !important;
	}

	.nav_btn_list button{
		display: none;
		width: 100%;
		padding: calc(var(--v1-win_width)*1/20) calc(var(--v1-win_width)*1/10);
		border-bottom: 1px solid var(--v1-white-color) !important;
	}

	.main_product_holder{
		justify-content: space-around;
	}

	.pic_word_with_btn .img_holder{
		height: calc(var(--v1-win_width)*0.5/var(--v1-main_page_item));
	}

	.slideshow-container {
		height: 100vh;
	}

	.title_holder{
		padding: 0 5%;
		width: 80%;
	}

	.arrow_icon {
		display: none;
	}

	.icon_word_section .img_holder img{
		width: 30%;
	}

	.main_section .pic_word_section, .small_word_pic_section{
		flex-wrap: wrap !important;
		padding: 20px 0;
	}

	.main_section .word_pic_section{
		flex-wrap: wrap-reverse !important;
		padding: 60px 0;
	}

	.pic_word_section img, .word_pic_section img{
		width: 100%;
	}



	.small_word_pic_section img {
		max-width: 20%;
		min-width: 80px;
	}

	.half_word_section{
		width: 80%;
	}

	.small_word_pic_section .half_word_section{
		width: 100%;
	}

	.partner_center {
		justify-content: space-between !important;
	}

	.partner_item .img_holder img{
		max-height: calc(var(--v1-win_width) * 1 / (8 * var(--v1-partner_num)));
	}

	.two_item_holder {
		width: 100%;
		padding: 0;
		justify-content: center;
	}

	.top_pic_down_word_section {
		width: 100%;
	}

	.top_pic_down_word_section .img_holder {
		width: 100%;
		height: auto;

		margin: 20px 0;
	}

	.down_word_section{
		width: 80%;
	}


}

@media only screen and (min-width: 550px) {
	:root {
		--v1-nav_bar_h: 50px;
		--v1-win_width: 500px;
		--v1-product_row_num: 3;
		--v1-list_row_num: 2;
		--v1-intro_row_num: 3;
		--v1-news_row_num: 1;
		--v1-main_page_item: 2;
		--v1-footer_item_num: 3;
		--v1-icon_item_num: 2;
		--v1-partner_num: 2;

		--v1-very-large-text: calc(50px);
		--v1-large-text: calc(40px);
		--v1-semi-large-text: calc(34px);
		--v1-normal-text: calc(24px);
		--v1-mid-small-text: calc(20px);
		--v1-small-text: calc(1.2vw);
		--v1-semi-small-text: calc(11px);
	}

	.nav_btn_holder{
		width: 100%;
		padding: calc(var(--v1-win_width)*1/40) 0;

		flex-flow: row;
		justify-content: space-around;

	}

	.nav_btn_holder .nav_btn_list{
		max-width: 20%;
	}

	.nav_btn_list h4{
		width: auto;
		padding: calc(var(--v1-win_width)*1/80) 0;
		border-bottom: 0 !important;
	}

	.nav_btn_list button{
		display: block;
		width: auto;
		padding: calc(var(--v1-win_width)*1/100) 0;
		border-bottom: 0 !important;
	}

	.slideshow-container {
/*		height: calc(var(--v1-win_width)*6/8);*/
		height: 100%;
	}

	.title_holder{
		padding: 0 6% 0 0;
		width: 40%;
	}

	.arrow_icon {
		display: none;
	}

	.pic_word_with_btn .img_holder{
		height: calc(var(--v1-win_width)*1/var(--v1-main_page_item));
	}

	.icon_word_section .img_holder img{
		width: 60%;
	}

	.small_word_pic_section img {
		width: 10%;
		padding: 20px 5%;
		max-width: 90px;
	}

	.small_word_pic_section .half_word_section{
		flex: 1;
		width: auto;
	}
}

@media only screen and (min-width: 800px) {
	:root {
		--v1-nav_bar_h: 60px;
		--v1-win_width: 600px;
		--v1-product_row_num: 3;
		--v1-list_row_num: 2;
		--v1-intro_row_num: 3;
		--v1-news_row_num: 1;
		--v1-main_page_item: 2;
		--v1-footer_item_num: 4;
		--v1-icon_item_num: 2;
		--v1-partner_num: 4;
	}

	.arrow_icon {
		display: block;
	}

	.main_section .pic_word_section, .small_word_pic_section, .word_pic_section{
		flex-wrap: nowrap !important;
		padding: 0;
	}

	.pic_word_section img, .word_pic_section img{
		width: 60%;
	}

	.half_word_section{
		flex: 1;
		width: auto;
	}

	.partner_center {
		justify-content: center !important;
	}

	.partner_item .img_holder img{
		max-height: calc(var(--v1-win_width) * 1 / (5 * var(--v1-partner_num)));
	}

	.two_item_holder {
		width: 90%;
		padding: 40px 5%;
	}

	.top_pic_down_word_section {
		width: 50%;
	}

	.top_pic_down_word_section .img_holder {
		width: 90%;
		height: 180px;

		margin: 20px 5%;
	}

	.down_word_section{
		width: 90%;
	}
}

@media only screen and (min-width: 1200px) {
	:root {
		--v1-nav_bar_h: 60px;
		--v1-win_width: 800px;
		--v1-product_row_num: 3;
		--v1-list_row_num: 3;
		--v1-intro_row_num: 4;
		--v1-news_row_num: 2;
		--v1-main_page_item: 4;
		--v1-footer_item_num: 5;
		--v1-icon_item_num: 4;
		--v1-partner_num: 4;
	}

	#body{
/*		width: 90% !important;*/
		margin: 0;
/*		padding: 0 5% !important;*/
	}

	.nav_bar{
		width: 90% !important;
	}

	.nav_btn_holder{
		width: 90%;
	}

	.main_product_holder {
		justify-content: space-between;
	}

	.two_item_holder {
		width: 100%;
		justify-content: space-between;
	}

	.top_pic_down_word_section {
		width: 48%;
	}

	.top_pic_down_word_section .img_holder {
		width: 100%;
		height: 260px;
		margin: 20px 0;
	}
}

body{
	font-family: 'Open Sans', 'Noto Sans TC', sans-serif;
}




/*main page*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
#body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

.nav_bar{
	width: 100%;
	min-height: var(--v1-nav_bar_h);
	background-color: var(--v1-white-color);

	position: fixed;
	top: 0;
	z-index: 1000;

	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items:center;
}



.nav_bar div{
	padding: 0;
	margin: 0;

	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items:center;
}

.nav_bar div .lang {
	font-size: var(--v1-small-text);
	background-color: transparent;
	border: 0;
	color: var(--grey-color);
	font-weight: 400;
	margin: 0 5px;

	height: var(--v1-nav_bar_h);
	cursor: pointer;

	padding: calc(var(--v1-win_width)*1/80);
}

.nav_bar div .lang:hover span{
	border-bottom: 1px var(--v1-dark-color) solid;
}

.vl {
	border-left: 1px solid var(--grey-color);
	height: calc((0.3) * var(--v1-nav_bar_h));
}

.nav_bar button{
	font-size: var(--v1-small-text);
	background-color: transparent;
	border: 0;
	color: var(--grey-color);
	font-weight: 800;
	margin: 0 5px;

	height: var(--v1-nav_bar_h);
	cursor: pointer;

	padding: calc(var(--v1-nav_bar_h)*3/10);
}

.nav_bar img{
	max-height: calc(var(--v1-nav_bar_h)*2/5);
	margin: 0;
}

#nav_btn{
	display: flex;
	justify-content: center;
	align-items: center;
}

#nav_btn img{
	max-height: calc(var(--v1-nav_bar_h)*2/5);
	max-height: calc(var(--v1-nav_bar_h)*2/5);
}

.nav_btn_holder{
	display: none;
	max-height: calc(100vh - var(--v1-nav_bar_h));
	background-color: var(--v1-light-grey-color);
	position: fixed;
	top: var(--v1-nav_bar_h);
	z-index: 1001;
	overflow: scroll;

}

.nav_btn_holder::-webkit-scrollbar {
  display: none;
}

.nav_btn_holder .nav_btn_list{
	display: flex;
	flex-flow: column;
	color: var(--grey-color);
}

.nav_btn_list h4{
	border: 0;
	text-align: left;
	background-color: transparent;
	margin: 0;
	font-weight: 700;
	cursor: pointer;
	font-size: var(--v1-small-text);
}

.nav_btn_list button{
	border: 0;
	text-align: left;
	background-color: transparent;
	color: var(--grey-color);
	margin: 0;
	font-weight: 400;
	font-size: var(--v1-small-text);
}

.nav_btn_list button:hover span{
	border-bottom: 1px var(--v1-dark-color) solid;
}











.main_section{
	width: calc(90% - 60px) !important;
/*	padding: var(--v1-nav_bar_h) 5% 0 5%;*/
	padding: 0 calc(5% + 30px);
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items:center;
}

.display_flex{
	display: flex;
}

.display_none{
	display: none;
}

/*.main_section .full_screen_pic{
	width: 100%;
	height: calc(var(--v1-win_width)*6/8);
	display: flex;
	flex-flow: column;
	justify-content: top;
	align-items:center;
	position: relative;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	overflow: hidden;
}

.main_section .half_screen_two_pic_holder{
	width: 100%;
	height: calc(var(--v1-win_width)*6/8);
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: top;
}

.half_screen_two_pic_holder .half_screen_pic{
	width: 50%;
	height: calc(var(--v1-win_width)*6/8);
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	position: relative;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	overflow: hidden;
}

.half_screen_pic div{
	position: absolute;
	top: 0;
}

.full_screen_pic div{
	position: absolute;
	top: 0;
}

.pic_title{
	font-size: var(--v1-semi-large-text);
	padding: calc(var(--v1-win_width)*5/80) 0 calc(var(--v1-win_width)*1/40);
}

.more_info_btn{
	margin: calc(var(--v1-win_width)*1/40) 0;
	border: none;
	background-color: var(--v1-dark-color);
	color: var(--v1-light-color);
}

.more_info_btn:hover{
	background-color: var(--v1-main-color);
}

.background_color_grey{
	background-color: var(--v1-light-grey-color)!important;
}

.background_color_white{
	background-color: var(--v1-light-color)!important;
}*/

.footer{
	width: 100%;
	/*height: calc(var(--v1-win_width)*3/8);*/
	background-color: var(--v1-semi-light-grey-color);

	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}

.footer .logo_holder{
	width: 10%;
	overflow: hidden;
	padding: calc(var(--v1-win_width)*1/80) 0;
}

.footer .logo_holder img{
	max-width: 100%;
}

.footer .footer_btn_section{
	width: 90%;
	/*height: calc(var(--v1-win_width)*5/16);*/

	padding: 0 5%;

	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.footer .footer_btn_section .footer_btn_list{
	display: flex;
	flex-flow: column;
	width: calc(90% / var(--v1-footer_item_num));
	padding: 20px 0 10px calc(10% / var(--v1-footer_item_num));
}

.footer_btn_list h4{
	text-align: left;
	border: 0;
	background-color: transparent;
	padding: 36px 0 4px;
	margin: 0;
	font-weight: 700;
	font-size: var(--v1-small-text);
}

.footer_btn_list button{
	text-align: left;
	border: 0;
	background-color: transparent;
	color: var(--v1-dark-color);
	padding: 8px 0;
	margin: 0;
	font-weight: 400;
	font-size: var(--v1-semi-small-text);
}

.footer_btn_list button:hover span{
	border-bottom: 1px var(--v1-dark-color) solid;
}

.footer .copyright_section{
	width: 100%;
	height: calc(var(--v1-win_width)*1/16);

	display: flex;
	justify-content: center;
	align-items: center;
}

.copyright_section{
	font-size: var(--v1-small-text);
}




/*main_product_holder*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.main_product_holder {
	width: 100%;
	display: flex;
	flex-flow: row;
	align-items: flex-start;
	flex-wrap: wrap;
}

.pic_word_with_btn{
	width: calc(88% / var(--v1-main_page_item));
	padding: 40px 0;
/*	cursor: pointer;*/
}

.pic_word_with_btn .img_holder{
	width: 100%;
	/*height: calc(var(--v1-win_width)*1/var(--v1-main_page_item));*/
	overflow: hidden;
	/*margin-bottom: 50px;*/
	display: flex;
	justify-content: center;
	align-items: center;
}

.pic_word_with_btn .img_holder img{
	/*min-width: 100%;*/
	width: 100%;
	/*min-height: 100%;*/
	height: 100%;
	object-fit: cover;
}

.pic_word_with_btn .info_holder h2{
	color: var(--v1-dark-color);
	font-size: var(--v1-mid-small-text);
	line-height: calc(1.3 * var(--v1-mid-small-text));
	word-wrap: break-word;
	margin-top: 20px;
	font-weight: 700;
}

.pic_word_with_btn .info_holder h5{
	color: var(--v1-dark-color);
	font-size: var(--v1-small-text);
	line-height: calc(1.5*var(--v1-small-text));
	font-weight: 400;
	word-wrap: break-word;
	margin-top: 20px;
}

.pic_word_with_btn .info_holder button{
	color: var(--v1-main-color);
	font-size: var(--v1-small-text);
	line-height: calc(1.3*var(--v1-small-text));
	font-weight: 600;
	word-wrap: break-word;
	margin-top: 20px;
	background-color: transparent;
	border: 0;
	text-align: left;
	padding: 0;
}



/*gallery*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.slideshow-container {
	width: 100vw;
/*	height: calc(var(--v1-win_width)*6/8);*/
	margin-left: -5vw;
	height: 80vh;
  position: relative;
 	margin: 0;
 	cursor: pointer;
}

.mySlides {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	/*height: calc(var(--v1-win_width)*6/8);*/
	height: 100%;
	overflow: hidden;

	flex-direction: row;
 	justify-content: flex-end;
 	align-items: center;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.mySlides img {
	height: 100%;
	width: auto;
	margin: 0 3% 0 0;
}

.title_holder{
	/*padding: 0 6% 0 0;
	width: 40%;*/
	word-wrap: break-word;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
 	align-items: flex-end;
}

.title_holder img{
	width: auto;
	height: calc(var(--v1-win_width) * 1/10);
	margin: 0;
}

.title_holder h1{
	margin-top: 3px;
	font-size: var(--v1-normal-text);
	line-height: calc(1.5*var(--v1-normal-text));
	color: var(--v1-white-color);
	text-align: right;
	font-weight: 700;
}

.title_holder h4{
	font-weight: 400;
	margin: 0 0 3px;
	font-weight: 400;
	font-size: var(--v1-small-text);
	line-height: calc(1.3*var(--v1-small-text));
	color: var(--v1-white-color);
	text-align: right;
	white-space: pre-line;
}

.title_holder button{
	background-color: var(--v1-white-color);
	color: var(--v1-dark-color);
	font-size: var(--v1-semi-small-text);
	font-weight: 600;
	width: -moz-fit-content;
	width: fit-content;
	padding: 8px 10px;
	margin: 5px 0;
	border: 0;
	cursor: pointer;
}

.numbertext {
	color: var(--v1-light-grey-color);
	font-size: var(--v1-semi-small-text);
	padding: calc(var(--v1-win_width)*1/100) calc(var(--v1-win_width)*3/200);
	position: absolute;
	top: 0;
}

.fade {
	-webkit-animation-name: fade;
	-webkit-animation-duration: 1.5s;
	animation-name: fade;
	animation-duration: 1.5s;
}

.fade_out {
	-webkit-animation-name: fade_out;
	-webkit-animation-duration: 1.5s;
	animation-name: fade_out;
	animation-duration: 1.5s;
}

@-webkit-keyframes fade {
	from {opacity: 0}
	to {opacity: 1}
}

@keyframes fade {
	from {opacity: 0}
	to {opacity: 1}
}

@-webkit-keyframes fade_out {
	from {opacity: 1}
	to {opacity: 0}
}

@keyframes fade_out {
	from {opacity: 1}
	to {opacity: 0}
}





/*tech*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.no_padding_top{
	padding-top: 0 !important;
}

.tech_section {
/*	padding-top: var(--v1-nav_bar_h);*/
}

.tech_nav {
	width: 100%;
	min-height: var(--v1-nav_bar_h);
	background-color: var(--v1-white-color);

	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items:center;
}

.tech_nav button{
	background-color: transparent;
	border: 0;
	margin: 0 5px;

	height: var(--v1-nav_bar_h);
	cursor: pointer;

	font-size: var(--v1-small-text);

	padding: calc(var(--v1-win_width)*1/80);
}

.tech_nav a{
	font-size: var(--v1-small-text);
	color: var(--grey-color);
	font-weight: 600;
	text-decoration: none;
}

.tech_nav button:hover a{
	color: var(--v1-main-color);
	border-color: var(--v1-main-color);
}

.clicked{
	border-bottom: 1px solid var(--v1-dark-color);
}

.ig_pic:hover{
	transform: scale(1.02);
	opacity: 0.8;
	z-index: 0;
	cursor: pointer;
}

/*.main_section .title_linePic_section{
	width: 100%;
	height: calc(var(--v1-win_width)*6/8);
	display: flex;
	flex-flow: row;
	justify-content: space-around;
	align-items:center;

	overflow: hidden;

	background-color: var(--v1-light-grey-color);
}

.title_linePic_section .tech_title{
	width: calc(var(--v1-win_width)*3/8);
	height: calc(var(--v1-win_width)*6/8);
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items:center;

	overflow: hidden;

}

.title_linePic_section .tech_title h2{
	padding: calc(var(--v1-win_width)*1/80) calc(var(--v1-win_width)*1/16);
	font-size: var(--v1-semi-large-text);
	line-height: calc(var(--v1-semi-large-text)*1.125);
	color: var(--v1-main-color);
	text-align: center;
}

.title_linePic_section .tech_title h5{
	padding: calc(var(--v1-win_width)*1/80) calc(var(--v1-win_width)*1/16);
	font-size: var(--v1-small-text);
	line-height: calc(var(--v1-small-text)*1.5);
	text-align: center;
	
}

.title_linePic_section .linePic{
	width: calc(var(--v1-win_width)*5/8);
	height: calc(var(--v1-win_width)*5/8);

	padding: calc(var(--v1-win_width)*1/16) 0;
}

.detail_pic{
	height: calc(var(--v1-win_width)*7/16)!important;
	object-fit: cover;
	object-position: 0 calc(var(--v1-win_width)*(-3)/16);;
}*/
.link_line {
	text-decoration: underline !important;
}

.main_section .word_section{
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items:center;

	overflow: hidden;

	padding: 20px 0;
}

.padding_small{
	padding: 0 0 !important;
}

.padding_small:nth-child(2){
	padding-top: calc(var(--v1-win_width)*1/10) !important;
}

.padding_small:last-child{
	padding-bottom: calc(var(--v1-win_width)*1/10) !important;
}



.word_section h3{
	width: 100%;
	font-size: var(--v1-semi-large-text);
	font-weight: 700;
	color: var(--v1-dark-color-color);
	line-height: calc(var(--v1-semi-large-text)*1.3);
	text-align: left;

	padding: calc(var(--v1-win_width)*1/20) 0 calc(var(--v1-win_width)*1/40);
}

.word_section h4{
	width: 100%;
	font-weight: 500;
	white-space: pre-line;
	font-size: var(--v1-mid-small-text);
	color: var(--v1-dark-color);
	line-height: calc(var(--v1-mid-small-text)*1.5);
	text-align: left;

	padding: 0 0 calc(var(--v1-win_width)*1/20);
}

.word_section a{
	font-size: var(--v1-small-text);
	white-space: pre-line;
	color: var(--v1-dark-color);
	line-height: calc(var(--v1-small-text)*1.5);
	text-align: left;
}

.main_section .word_btn{
	width: 100%;
	display: flex;
	overflow: hidden;
	padding: 20px 0;
	cursor: pointer;
}

.word_btn div{
	width: 100%;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	background-color: var(--v1-light-grey-color);
}

.word_btn h3{
	font-size: var(--v1-semi-large-text);
	font-weight: 700;
	color: var(--v1-dark-color);
	line-height: calc(var(--v1-semi-large-text)*1.3);
	text-align: left;
	padding: calc(var(--v1-win_width)*1/20) 5%;
}

.word_btn h4{
	font-size: var(--v1-semi-large-text);
	font-weight: 700;
	color: var(--v1-dark-color);
	line-height: calc(var(--v1-semi-large-text)*1.3);
	text-align: left;
	padding: calc(var(--v1-win_width)*1/20) 5%;
}

.word_btn:hover {
	transform: scale(1.02);
	opacity: 0.7;
}


.main_section .pic_pic_section{
	width: 100%;
	/*height: calc(var(--v1-win_width)*6/8);*/
	padding: calc(var(--v1-win_width)*1/10) 0;
	display: flex;
	flex-flow: row;
	justify-content: space-around;
	align-items:center;

	overflow: hidden;
	background-color: var(--v1-light-grey-color);
}

.pic_pic_section img{
	width: calc(var(--v1-win_width)*3/8);
	height: calc(var(--v1-win_width)*3/8);
}

.main_section .list_section{
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-flow: column;

	background-color: var(--v1-light-grey-color);
}

.main_section .list_section:nth-child(even){
	background-color: var(--v1-white-color);
}

.list_section .list_btn_style{
	width: auto;
	text-decoration: underline;
}

.list_section .list_btn_style:hover{
	text-decoration: underline;
	color: var(--v1-main-color);
}

.list_holder {
	display: flex;
	flex-flow: row;
}

@media only screen and (max-width: 680px) {
	.list_holder {
		flex-flow: column;
	}
}

.list_title{
	/*flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 100%;*/
/*	min-width: calc(100% - var(--v1-win_width)*1/8) !important;*/
	padding: calc(var(--v1-win_width)*1/16) calc(var(--v1-win_width)*1/16) 0;
	font-size: var(--v1-normal-text);
	line-height: calc(1.5*var(--v1-normal-text));
	cursor: pointer;
}

.list_item{
	min-width: 30%;
	/*flex-grow: 0;
	flex-shrink: 0;*/
/*	flex-basis: calc(90%/var(--v1-list_row_num));*/
	margin: 0 0 0 calc(10% / (var(--v1-list_row_num) + 1));
	padding: calc(var(--v1-win_width)*1/40) 0 calc(var(--v1-win_width)*1/16);
}

.list_item hr{
	border-style: solid;
	border-width: 1px;
	border-color: var(--v1-main-color);
	background-color: var(--v1-main-color);
	display: none;
}

.list_item h3{
	text-decoration: underline;
	font-size: var(--v1-small-text);
	color: var(--v1-main-color);
	line-height: calc(var(--v1-small-text)*1.3);

	padding: calc(var(--v1-win_width)*1/40) calc(var(--v1-win_width)*1/40) 10px;
}

.list_item h5{
	font-size: var(--v1-small-text);
	color: var(--v1-dark-color);
	line-height: calc(var(--v1-small-text)*1.75);

	padding: 0 calc(var(--v1-win_width)*1/40) calc(var(--v1-win_width)*1/100);
}



.main_section .two_item_holder {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	align-items:flex-start;

	overflow: hidden;
}


.top_pic_down_word_section {
	display: flex;
	flex-flow: column;
	align-items:center;
	justify-content: center;
	overflow: hidden;
}

.top_pic_down_word_section .img_holder {
	display: flex;
	justify-content: center;
	align-items: center;

	overflow: hidden;
}

.top_pic_down_word_section img{
	width: 100%;
	height: auto;
}

.down_word_section{
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items:center;
	padding: 20px 5%;
}

.down_word_section h3{
	width: 100%;
	font-size: var(--v1-semi-large-text);
	font-weight: 700;
	color: var(--v1-dark-color);
	line-height: calc(var(--v1-semi-large-text)*1.125);
	text-align: left;
	padding: calc(var(--v1-win_width)*1/40) 0;
}

.down_word_section h4{
	width: 100%;
	font-size: var(--v1-small-text);
	font-weight: 400;
	color: var(--v1-dark-color);
	line-height: calc(var(--v1-small-text)*1.5);
	text-align: left;
}




.cursor_pointer{
	cursor: pointer;
}

.small_word_pic_section:last-child {
	padding-bottom: 60px !important;
}

.main_section .pic_word_section, .word_pic_section, .small_word_pic_section{
	width: 100%;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items:center;

	overflow: hidden;
}

.pic_word_section img, .word_pic_section img, .small_word_pic_section img{
	height: auto;
}

.half_word_section{
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items:center;
	padding: 20px 5%;
}

.half_word_section h3{
	width: 100%;
	font-size: var(--v1-semi-large-text);
	font-weight: 700;
	color: var(--v1-dark-color);
	line-height: calc(var(--v1-semi-large-text)*1.125);
	text-align: left;
	padding: calc(var(--v1-win_width)*1/40) 0;
}

.half_word_section h4{
	width: 100%;
	font-size: var(--v1-small-text);
	font-weight: 400;
	/*padding: 0 0 calc(var(--v1-win_width)*1/40);*/
	color: var(--v1-dark-color);
	line-height: calc(var(--v1-small-text)*1.5);
	text-align: left;
}


.icon_word_holder {
	width: 90%;
	padding: calc(var(--v1-win_width)*1/20) 5%;
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
}

.icon_word_section{
	width: calc((60% / var(--v1-icon_item_num)));
	max-width: 155px;
	align-self: stretch;
	margin: 10px calc(12% / var(--v1-icon_item_num));
	padding: calc(var(--v1-win_width)*1/26) calc(8% / var(--v1-icon_item_num)) calc(var(--v1-win_width)*1/20);
	box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
	/*background-color: var(--v1-light-grey-color);*/
}

.icon_word_section .img_holder{
	width: 100%;
	height: 90px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon_word_section .img_holder img{
	height: auto;
	max-width: 70px;
}

.icon_word_section .info_holder h1{
	color: var(--v1-dark-color);
	font-size: var(--v1-normal-text);
	line-height: calc(1.3*var(--v1-normal-text));
	word-wrap: break-word;
	margin-top: 20px;
	font-weight: 600;
}

/*.detail_product_section{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.product_pic{
	height: calc(var(--v1-win_width)*8/16);
	flex: 1 1 calc(100%/var(--v1-product_row_num));
	display: flex;
	flex-flow: column;
	justify-content: top;
	align-items: center;

	background-position: center;
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-color: var(--v1-light-grey-color);

	overflow: hidden;
}

.product_pic:nth-child(2n) {
	background-color: var(--v1-white-color)!important;
}

.product_pic div{
	display: flex;
	flex-flow: column;
	justify-content: top;
	align-items: center;
}

.pic_title{
	font-size: var(--v1-normal-text);
	padding: calc(var(--v1-win_width)*1/30) 0 calc(var(--v1-win_width)*1/40);
	text-align: center;
}

.more_info_btn{
	border: none;
	background-color: var(--v1-dark-color);
	color: var(--v1-light-color);
}

.more_info_btn:hover{
	background-color: var(--v1-main-color);
}*/







/*patent page*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.main_section .pic_section{
	width: 90%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;

	cursor: pointer;

	overflow: hidden;

	padding: calc(var(--v1-win_width)*1/20) 5%;
}

.pic_section .pic_item{
	/*width: 22.5%;*/
	/*margin: calc(var(--v1-win_width)*1/20) 0;*/

	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
}

.pic_item .img_holder{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pic_item .img_holder img{
	width: 100%;
	height: auto;
}

.pic_item h3{
	color: var(--grey-color);
	font-size: var(--v1-normal-text);
	font-weight: 400;
	padding: calc(var(--v1-win_width)*1/40) 0;
}






/*partner page*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.main_section .partner_section{
	width: 90%;
	/*max-width: calc((200px) * var(--v1-partner_num));*/
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;

	cursor: pointer;

	overflow: hidden;

	padding: calc(var(--v1-win_width)*1/20) 5%;
}


.partner_section .partner_item{
	width: calc(76% / var(--v1-partner_num));
	margin: calc(var(--v1-win_width)*1/20) calc(12% / var(--v1-partner_num));

	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
}

.partner_item .img_holder{
	display: flex;
	justify-content: center;
	align-items: center;
}

.partner_item .img_holder img{
	max-width: 100%;
	width: auto;
	/*max-height: calc(var(--v1-win_width) * 1 / (2 * var(--v1-partner_num)));*/
}

.partner_item .partner_item h3{
	color: var(--grey-color);
	font-size: var(--v1-small-text);
	font-weight: 400;
	padding: calc(var(--v1-win_width)*1/40) 0;
}







/*self 5w page*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.main_section .five_w_holder{
	width: 100%;

	overflow: hidden;

	padding: 20px 0;
}

.five_w_holder .five_w_item{
	padding: calc(var(--v1-win_width)*1/20) 5%;
}

.five_w_item p{
	font-size: var(--v1-small-text);
	line-height: calc(1.3*var(--v1-small-text));
	color: var(--grey-color);
}

.five_w_item span{
	font-size: var(--v1-semi-large-text);
	line-height: calc(1.3*var(--v1-semi-large-text));
	font-weight: 900;
	color: var(--grey-color);
}




.main_section .title_section_holder{
	width: 90%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;

	overflow: hidden;

	padding: 40px 5% 20px;
}

.title_section_holder h1{
	font-size: var(--v1-normal-text);
	line-height: calc(1.5*var(--v1-normal-text));
	font-weight: 700;
	color: var(--v1-dark-color);
}

.title_section_holder a{
	font-size: var(--v1-normal-text);
	line-height: calc(1.5*var(--v1-normal-text));
	font-weight: 700;
	white-space: pre-line;
	color: var(--v1-dark-color);
}





/*self intro page*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.main_section .intro_section{
	width: calc(100% - (var(--v1-win_width)*2/20));
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;

	padding: calc(var(--v1-win_width)*1/28) calc(var(--v1-win_width)*1/20);

	overflow: hidden;
}

.intro_section .intro_item{
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: calc(85%/var(--v1-intro_row_num));
	display: flex;
	flex-flow: column;
	justify-content: top;
	align-items: flex-start;

	margin: calc(var(--v1-win_width)*1/40) calc(15%/(var(--v1-intro_row_num) * 2 + 1));

	border-radius: calc(var(--v1-win_width)*1/50);
	overflow: hidden;

	background-color: var(--v1-light-color);
}

.intro_section .img_holder{
	height: calc(var(--v1-win_width)*1/4);
	width: 100%;
	/*background-color: black;*/

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.intro_section .intro_item_detail{
	padding: calc(var(--v1-win_width)*1/40);
}

.intro_pic_title{
	font-size: var(--v1-mid-small-text);
	line-height: calc(var(--v1-mid-small-text)*1.25);
	padding: 0 0 calc(var(--v1-win_width)*1/50);
}

.intro_pic_sub_title{
	color: var(--v1-main-color);
	font-size: var(--v1-semi-small-text);
}



/*news page*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.main_section .news_section{
	width: calc(100% - (var(--v1-win_width)*2/20));
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;

	padding: calc(var(--v1-win_width)*1/28) calc(var(--v1-win_width)*1/20);

	overflow: hidden;
}

.news_section .news_item{
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: calc(85%/var(--v1-news_row_num));
	display: flex;
	flex-flow: column;
	justify-content: top;
	align-items: flex-start;

	margin: calc(var(--v1-win_width)*1/40) calc(15%/(var(--v1-news_row_num) * 2 + 1));

	border-radius: calc(var(--v1-win_width)*1/50);
	overflow: hidden;

	background-color: var(--v1-light-color);
}

.news_section .img_holder{
	height: calc(var(--v1-win_width)*1/4);
	width: 100%;
	/*background-color: black;*/

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.news_section .news_item_detail{
	font-size: var(--v1-semi-small-text);
	padding: calc(var(--v1-win_width)*1/40);
}

.news_pic_title{
	font-size: var(--v1-mid-small-text);
	line-height: calc(var(--v1-mid-small-text)*1.5);
	padding: calc(var(--v1-win_width)*1/40) 0 calc(var(--v1-win_width)*1/40);
}

.news_pic_sub_title{
	color: var(--v1-main-color);
}

.news_item_detail .more_info_btn{
	margin: calc(var(--v1-win_width)*1/40) 0;
	border: none;
	background-color: var(--v1-dark-color);
	color: var(--v1-light-color);
	scale: 150%;
	margin-left: 14px
}

.news_item_detail .more_info_btn:hover{
	background-color: var(--v1-main-color);
}







/*contact us page*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.contact_us_holder{
	width: calc(100% - var(--v1-win_width)*1/10);

	padding: calc(var(--v1-win_width)*1/20) calc(var(--v1-win_width)*1/20) calc(var(--v1-win_width)*1/10);
}

.contact_us_holder h1{
	font-size: var(--v1-semi-large-text);
	font-weight: 600;
	padding: calc(var(--v1-win_width)*1/20) 0 calc(var(--v1-win_width)*1/40);
}

.contact_us_holder h2{
	font-size: var(--v1-normal-text);
	font-weight: 600;
	padding: calc(var(--v1-win_width)*1/40) 0;
}

.contact_us_holder h3{
	font-size: var(--v1-normal-text);
	font-weight: 400;
	text-decoration: underline;
	padding: calc(var(--v1-win_width)*1/40) 0;
}

.contact_us_holder h6{
	font-size: var(--v1-small-text);
	font-weight: 400;
	padding: calc(var(--v1-win_width)*1/40) 0;
}

.contact_us_holder button{
	margin: calc(var(--v1-win_width)*1/40) 0;
	border-radius: calc(var(--v1-win_width)*1/30);
	background-color: var(--v1-dark-color);
	border: 0;
	padding: calc(var(--v1-win_width)*1/60) calc(var(--v1-win_width)*1/40);
}

.contact_us_holder button:hover{
	background-color: var(--v1-main-color);
}

.contact_us_holder a{
	font-size: var(--v1-small-text);
	font-weight: 400;
	text-decoration: none;
	
	color: var(--v1-white-color);
}







/*blog page*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.main_blog_section{
	width: 100%;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items:flex-start;
}

.main_section .search_section{
	width: 25%;

	padding: calc(var(--v1-win_width)*1/10) calc(var(--v1-win_width)*1/20) calc(var(--v1-win_width)*1/10) 0;

	overflow: hidden;
}

.category_title{
	font-size: var(--v1-mid-small-text);
	color: var(--v1-dark-color);
	padding: calc(var(--v1-win_width)*1/80) 0;
}

.category_section hr{
	border-width: calc(var(--v1-win_width)*1/600);
	border-color: var(--v1-dark-color);
	border-style: solid;
	background-color: var(--v1-dark-color);
}

.category_btn{
	padding: calc(var(--v1-win_width)*1/80) 0;
}

.category_btn a{
	font-size: var(--v1-small-text);
	color: var(--v1-dark-color);
	line-height: calc(var(--v1-small-text)*1.5);
	text-decoration: none;
}

.category_btn hr{
	border-width: calc(var(--v1-win_width)*1/800);
	border-color: var(--v1-light-grey-color);
	border-style: solid;
	background-color: var(--v1-light-grey-color);

	margin: calc(var(--v1-win_width)*1/60) 0;
}

.main_section .blog_section{
	width: calc(75% - (var(--v1-win_width)*2/14));
	display: flex;
	flex-flow: column;
	justify-content: top;
	align-items: flex-start;

	padding: calc(var(--v1-win_width)*1/28) calc(var(--v1-win_width)*1/14);

	overflow: hidden;
}

@media only screen and (max-width: 700px) {
	.main_section .search_section{
		display: none;
	}

	.main_section .blog_section{
		padding: calc(var(--v1-win_width)*1/28) 0;
		width: auto;
	}

}

.blog_section .blog_item{
	display: flex;
	flex-flow: column;
	justify-content: top;
	align-items: flex-start;

	width: 100%;

	overflow: hidden;

	background-color: var(--v1-light-color);
}

.blog_item .img_holder{
	height: calc(var(--v1-win_width)*1/2);
	width: 100%;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.blog_item_detail .pic_title{
	width: 100%;
	font-size: var(--v1-normal-text);
	line-height: calc(var(--v1-normal-text)*1.25);
	padding: calc(var(--v1-win_width)*1/80) 0 calc(var(--v1-win_width)*1/80);
}

.blog_item_detail .pic_sub_title{
	width: 100%;
	font-size: var(--v1-small-text);
	color: var(--v1-main-color);
	line-height: calc(var(--v1-small-text)*1.25);
	padding: calc(var(--v1-win_width)*1/60) 0 calc(var(--v1-win_width)*1/60);
}

.blog_content{
	width: 100%;
	font-size: var(--v1-small-text);
	line-height: calc(var(--v1-small-text)*1.75);
	padding: calc(var(--v1-win_width)*1/60) 0 calc(var(--v1-win_width)*1/60);
}

.blog_content a{
	cursor: pointer;
	text-decoration: underline;
	font-size: var(--v1-small-text);
	color: var(--v1-main-color);
}

.hashtag{
	padding: calc(var(--v1-win_width)*1/60) 0;
}

#more{
	display: none;
}

.lineBreaker{
	width: 100%;
	height: calc(var(--v1-win_width)*3/160);
	background-size: auto calc(var(--v1-win_width)*3/160);
	background-repeat: no-repeat;
	background-position: center;

	margin: calc(var(--v1-win_width)*1/30) 0;
}





/*sale page*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.main_section .sale_section{
	padding: 0 10%;
	width: 80%;
	height: calc(var(--v1-win_width)*8/8);
	display: flex;
	flex-flow: row;
	justify-content: space-around;
	align-items:center;

	overflow: hidden;

	background-color: var(--v1-white-color);
}

.sale_section .sale_detail_section{
	width: 60%;
	height: calc(var(--v1-win_width)*7/8);
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items:center;

	margin: calc(var(--v1-win_width)*1/16) 0;

	overflow-y: scroll;
}

.sale_detail_section::-webkit-scrollbar {
  display: none;
}

.sale_section .sale_detail_section h2{
	width: calc(100% - var(--v1-win_width)*1/8);
	padding: calc(var(--v1-win_width)*1/80) calc(var(--v1-win_width)*1/16);
	font-size: var(--v1-semi-large-text);
	line-height: calc(var(--v1-semi-large-text)*1.125);
	color: var(--v1-main-color);
	text-align: left;
	font-weight: 800;
}

.sale_section .sale_detail_section h5{
	width: calc(100% - var(--v1-win_width)*1/8);
	padding: calc(var(--v1-win_width)*1/80) calc(var(--v1-win_width)*1/16);
	font-size: var(--v1-small-text);
	line-height: calc(var(--v1-small-text)*1.5);
	text-align: left;
	font-weight: 400;
	
}

.sale_btn_section{
	width: calc(100% - var(--v1-win_width)*1/8);
	padding: calc(var(--v1-win_width)*1/30) calc(var(--v1-win_width)*1/16) 0 calc(var(--v1-win_width)*1/16);
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;
}

.sale_btn_title{
	font-size: var(--v1-normal-text);
	font-weight: 600;
	padding: calc(var(--v1-win_width)*1/100) 0;
}

.sale_btn_holder{
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;

	padding: 0 0 calc(var(--v1-win_width)*1/20) 0;

	border-width: 0 0 1px 0;
	border-color: var(--v1-light-grey-color);
	border-style: solid;
}

.sale_btn_holder .sale_btn{
	border-width: 1px;
	border-style: solid;
	border-color: #ddd;
	background-color: transparent;
	width: 100%;
	height: calc(var(--v1-win_width)*1/16);
	box-sizing: border-box;
	border-radius: 4px;

	margin: calc(var(--v1-win_width)*1/100) 0;

	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
}

.sale_btn_holder .sale_btn:hover {
	border-color: var(--v1-main-color);
	color: var(--v1-main-color);
}

.selected {
	border-color: var(--v1-main-color) !important;
	color: var(--v1-main-color);
}

.sale_btn h4{
	font-size: var(--v1-small-text);
	font-weight: 700;
}

.sale_btn h6{
	font-size: var(--v1-small-text);
	font-weight: 400;
}

.sale_section .sale_pic{
	width: 40%;
}

.add_to_cart {
	width: calc(100% - var(--v1-win_width)*3/16);
	background-color: var(--v1-light-grey-color);
	padding: calc(var(--v1-win_width)*1/32);
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}

.add_to_cart_info{
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;

	padding: 0 0 calc(var(--v1-win_width)*1/40) 0;

	border-width: 0 0 1px 0;
	border-color: var(--v1-semi-grey-color);
	border-style: solid;
}

.add_to_cart_info .total_price{
	width: 100%;
	font-size: var(--v1-semi-large-text);
	font-weight: 600;
	padding: calc(var(--v1-win_width)*1/100) 0;

	display: flex;
	flex-flow: row;
	align-items: flex-start;
}

.add_to_cart_info .total_price .product_price {
	width: auto;
	text-decoration: line-through;
	font-size: var(--v1-semi-large-text);
	font-weight: 600;
	padding: 0 0;
}

.add_to_cart_info .total_price .discount_price {
	width: auto;
	margin: 0 0 0 5px;
	color: var(--v1-main-color);
	font-size: var(--v1-semi-large-text);
	font-weight: 600;
	padding: 0 0;
}

.add_to_cart_info p{
	width: 100%;
	font-size: var(--v1-small-text);
	font-weight: 400;
	padding: calc(var(--v1-win_width)*1/100) 0;
}


.add_to_cart_btn{
	width: 100%;
	height: calc(var(--v1-win_width)*1/18);
	margin: calc(var(--v1-win_width)*1/40) 0;
	border-radius: 4px;
	background-color: var(--v1-main-color);
	font-size: var(--v1-normal-text);
	color: white;
	border: 0;
}

.ad_display_holder{
	width: 100%;
	height: auto;
}

.ad_display_holder img{
	width: 100%;
	height: auto;
}






.general_section {
	background-color: var(--v1-light-grey-color);
}

.general_section:nth-child(2n) {
	background-color: var(--v1-white-color)!important;
}


.background_color_grey{
	background-color: var(--v1-light-grey-color)!important;
}

.background_color_white{
	background-color: var(--v1-light-color)!important;
}

.small_text{
	font-size: var(--v1-small-text)!important;
}

.left_text{
	text-align: left!important;
}





/*cart page*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////////*/
.main_section .cart_section{
	width: 80%;
	margin: calc(var(--v1-win_width) * 1/20) 0;

	display: flex;
	flex-flow: column;
}

.cart_section .shop_item_section{
	width: 90%;
	margin: calc(var(--v1-win_width) * 1/40) 0;
	padding: calc(var(--v1-win_width) * 1/40) 5%;
	border-radius: 4px;

	display: flex;
	flex-flow: column;

	background-color: var(--v1-light-color);
}

.shop_item_info{
	width: 100%;
	padding: calc(var(--v1-win_width) * 1/40) 0;

	border-width: 0 0 1px 0;
	border-color: var(--v1-dark-color);
	border-style: solid;

	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;	
}

.shop_item_info img{
	width: 15%;
}

.shop_item_info h3{
	width: 43%;
	font-size: var(--v1-large-text);
}

.shop_item_info button{
	width: 10%;
	font-size: var(--v1-normal-text);
	font-weight: 400;
	border-radius: 4px;
	border: 0;
	color: var(--v1-white-color);
	background-color: var(--v1-dark-color);
}

.shop_item_info button:hover{
	background-color: var(--v1-main-color);
}

.shop_item_info .shop_item_info_holder{
	width: 18%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}

.shop_item_info_holder h5{
	font-size: var(--v1-semi-large-text);
	width: 100%;
	font-weight: 600;
	margin: calc(var(--v1-win_width) * 1/40) 0 0 0;
	color: var(--v1-main-color);
}

.shop_item_info_holder .amount{
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
}

.amount input{
	font-weight: 400;
	width: 40%;
	min-width: 45px;
	box-sizing: border-box;
	font-size: var(--v1-normal-text);
	height: calc(var(--v1-normal-text)*1.5);
	border-radius: 4px;
	border-width: 1px;
	border-style: solid;
	border-color: var(--v1-semi-grey-color);
}

.amount label{
	font-weight: 400;
	font-size: var(--v1-normal-text);
	height: var(--v1-normal-text);
	line-height: var(--v1-normal-text);
	vertical-align: center;
	
}


.cart_section .self_info_section{
	width: 90%;
	margin: calc(var(--v1-win_width) * 1/40) 0;
	padding: calc(var(--v1-win_width) * 1/20) 5%;
	border-radius: 4px;

	display: flex;
	flex-flow: column;

	background-color: var(--v1-light-color);
}

.self_info_section h2{
	font-size: var(--v1-semi-large-text);
	color: var(--v1-main-color);
	font-weight: bold !important;
	margin: var(--v1-semi-large-text) 0;
}

.self_info_section p{
	font-size: var(--v1-small-text);
	padding: var(--v1-normal-text) 0;
	margin: 0 0 calc(var(--v1-win_width) * 1/20) 0;
	font-weight: 400;
	color: var(--v1-semi-grey-color);

	border-width: 0 0 1px 0;
	border-color: var(--v1-semi-grey-color);
	border-style: solid;
}

.self_info_section label{
	font-size: var(--v1-normal-text);
}

.self_info_section input{
	font-size: var(--v1-normal-text);
	box-sizing: border-box;
	width: 100%;
	padding: calc(var(--v1-normal-text)*0.5);
	margin: 0 0 var(--v1-normal-text) 0;
	height: calc(var(--v1-normal-text)*2);
	border-radius: 4px;

	border-color: var(--v1-semi-grey-color);
	border-style: solid;
}

.self_info_section button{
	width: 100%;
	box-sizing: border-box;
	padding: calc(var(--v1-semi-large-text)*0.5) 0;
	font-size: var(--v1-semi-large-text);
	border-radius: 4px;
	color: var(--v1-white-color);
	background-color: var(--v1-main-color);
	border: 0;
}

.shop_item_detail {
	width: 100%;
}


.shop_item_detail .addition_item{
	width: 96%;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;

	border-width: 0 0 1px 0;
	border-color: var(--v1-light-grey-color);
	border-style: solid;

	font-size: var(--v1-normal-text);
	font-weight: 400;
	padding: calc(var(--v1-normal-text)*0.5) 2%;
	height: calc(var(--v1-normal-text)*2);
}

.addition_item h4{
	width: 63%;
}

.addition_item h6{
	width: 20%;
}

.addition_item button{
	box-sizing: border-box;
	font-size: var(--v1-small-text);
	font-weight: 400;
	border-width: 1px;
	border-color: var(--v1-dark-color);
	border-style: solid;
	border-radius: 4px;
	background-color: transparent;
}

.addition_item button:hover{
	border-color: var(--v1-main-color);
	color: var(--v1-white-color);
	background-color: var(--v1-main-color);
}

.threeDDisplay{
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}