.opening_section {
/*	background-color: red;*/
	width: 80vw;
/*	height:56vw;*/
	height: 38vw;
	padding: 0 10vw;

	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
}

.slide_area {
	width: 96vw;
	height: 35vw;
	overflow: hidden;

/*	margin: 0 7vw;*/
	margin: 0vw 2vw;

	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;

/*	background-color: red;*/

	opacity: 0;
}

.slide_area img{
	width: 90vw;
}

.v2_slideshow-container {
	width: 100%;
	height: 100%;
  	position: relative;
 	margin: auto;
 	cursor: pointer;
}



.v2_slideshow-container .mySlides {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
	overflow: hidden;

	flex-direction: row;
 	justify-content: space-between;
 	align-items: center;

/*	background-position: center;*/
	background-position: left;
	background-size: cover;
	background-repeat: no-repeat;
}

.v2_slideshow-container .mySlides img {
	height: 100%;
	width: auto;
	margin-right: 7vw;
}

.title_holder{
	word-wrap: break-word;
	margin: 0 5vw 0 0;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
 	align-items: flex-end;
}

.title_holder img{
	width: auto;
	height: 7vw;
	margin: 0;
}

.title_holder h1{
	margin-top: 3px;
	font-size: var(--h2-text);
	line-height: calc(1.5*var(--h2-text));
	color: var(--white-color);
	text-align: right;
	font-weight: 700;
}

.title_holder h4{
	font-weight: 400;
	margin: 0 0 3px;
	font-weight: 400;
	font-size: var(--h2-text);
	line-height: calc(1.3*var(--h2-text));
	color: var(--white-color);
	text-align: right;
	white-space: pre-line;
}

.title_holder button{
	background-color: var(--white-color);
	color: var(--dark-color);
	font-size: var(--content-text);
	font-weight: 600;
	width: -moz-fit-content;
	width: fit-content;
	padding: 8px 10px;
	margin: 5px 0;
	border: 0;
	cursor: pointer;
}

.v2_title_holder{
/*	max-width: 50%;*/
	word-wrap: break-word;
	margin: 2vw 5vw 0 0;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
 	align-items: flex-end;

 	float: left;
}


.v2_title_holder h1{
	margin-top: 3px;
	padding-bottom: 7px;
	font-size: var(--slide-title);
	line-height: calc(var(--slide-title));
	color: var(--white-color);
	text-align: right;
	font-weight: 700;
}

.v2_title_holder h4{
	max-width: 50vw;
	font-weight: 400;
	margin: 20px 0px 3px 20px;
	font-weight: 400;
	font-size: var(--slide-font);
	line-height: calc(1.5*var(--slide-font));
	color: var(--white-color);
	text-align: right;
	white-space: pre-line;
}

.numbertext {
	color: var(--light-grey-color);
	font-size: var(--content-text);
	padding: 1vw 1.5vw;
	position: absolute;
	top: 0;
}

.fade {
	-webkit-animation-name: fade;
	-webkit-animation-duration: 1.5s;
	animation-name: fade;
	animation-duration: 1.5s;
}
.fade_in {
	-webkit-animation-name: fade-in;
	-webkit-animation-duration: 1.5s;
	animation-name: fade-in;
	animation-duration: 1.5s;
}
.fade_out {
	-webkit-animation-name: fade-out;
	-webkit-animation-duration: 1.5s;
	animation-name: fade-out;
	animation-duration: 1.5s;
}

@-webkit-keyframes fade {
	from {opacity: 0;margin-right: 30vw;}
	to {opacity: 1;margin-right: 3vw;}
}

@keyframes fade {
	from {opacity: 0;margin-right: 50vw;}
	to {opacity: 1;margin-right: 7vw;}
}







.opening_area_x_logo {
	width: 100vw;
	height: 38vw;

	position: absolute;
	top: 0;
	left: 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.opening_area_x_logo img {
	width: 20vw;
}




.opening_area_nexuni_logo {
	width: 60vw;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}

.opening_area_nexuni_logo img {
	height: 5vw;
	opacity: 0;
}

#opening_area_nexuni_logo_i {
	height: 7vw;
}







@keyframes fade-in {
  from {
  	opacity: 0;
  }
  to {
  	opacity: 100;
  }
}

@keyframes fade-out {
  from {
  	opacity: 100;
  }
  to {
  	opacity: 0;
  }
}

@keyframes move-left {
  from {
  	padding-right: 0;
  }
  to {
  	padding-right: 30vw;
  }
}

@keyframes move-right {
  from {
  	padding-left: 0;
  }
  to {
  	padding-left: 30vw;
  }
}

@media screen and (max-width:780px) {
	.opening_section {
		height: 90vh;
	}
	.opening_area_x_logo {
		height: 90vh;
	}
	.slide_area {
		width: 100vw;
		height: 90vh;
		margin: 0;
	}
	.v2_title_holder h4{
		max-width: 100vw;
	}
}

@media screen and (max-width:350px) {
}















