.marquee {
  width: 100vw;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 1vw;
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1vw;
  min-width: 100%;
  animation: scroll-x 40s linear infinite;
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 1vw));
  }
}

/* Element styles */
.marquee img {
  display: grid;
  place-items: center;
  width: 10vw;
  min-width: 150px;
  padding: 20px 10px;
}


