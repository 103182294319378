.v2_nav_section {
	background-color: var(--white-color);
/*	background-color: red;*/
	width: 90vw;
	height: 6vw;
	padding: 0 5vw;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.v2_nav_logo {
	height: 2vw;
}

.v2_nav_logo:hover {
	transform: scale(1.1); /* Slightly enlarges the image */
}

.v2_nav_btn_holder {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 2vw;
	font-size: var(--nav-font);
}

.v2_nav_lang span {
	font-weight: 600;
	font-size: var(--nav-font);
}
.v2_nav_lang_btn {
	font-weight: 600;
	font-size: var(--nav-font);
	line-height: 10vh;
}

.v2_nav_lang_btn:hover {
	color: var(--middle-grey-color);
}

.v2_nav_btn {
    color: var(--black-color);
    font-size: var(--nav-font);
    font-weight: 600;
}

.v2_nav_btn:hover {
    color: var(--middle-grey-color);
}

.v2_append_nav_btn {
	display: none;
}

.v2_mobile_tab {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
/*	gap: 5vw;*/
	font-size: var(--nav-font);
	color: var(--black-color);
	background-color: var(--light-grey-color);
}

.v2_mnav_btn {
    color: var(--black-color);
    font-size: var(--nav-font);
    padding: 10px;
/*    height: 6vh;*/
/*    width: 16vw;*/
    line-height: calc(1.2*var(--nav-font));
    text-align: center;
}

.v2_mnav_btn:hover {
    color: var(--middle-grey-color);
}


@media screen and (max-width:780px) {
	.v2_nav_section {
		height: 10vh;
		padding: 0 5vw;
	}
	.v2_nav_logo {
		height: 3vh;
	}
	.v2_nav_btn_holder {
		font-size: calc(3*var(--nav-font));
	}

	.v2_nav_btn {
		display: none;
	}

	.v2_append_nav_btn {
		display: block;
		font-size: calc(1.5*var(--nav-font));
	}

	/*.v2_mnav_btn {
	    font-size: calc(0.8*var(--nav-font));
	}*/
}

@media screen and (max-width:350px) {
}